import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilteredBy";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import ModalViewer from "../../ModalViewer";
import { FailureReasonContext } from "../FailureReasonProvider";
import FailureReasonFormContainer from "../containers/FailureReasonFormContainer";
import FailureReasonViewContainer from "../containers/FailureReasonViewContainer";
import ExportForm from "./ExportForm";
import FailureReasonListFilter from "./FailureReasonListFilter";

const FailureReasonListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const failureReasonContext = useContext(FailureReasonContext);
  const { failureReasonList, pagination } = failureReasonContext;
  const {
    filterParams,
    handleApplyFilter,
    loadFailureReasonList,
    deleteFailureReason,
    failureReasonLoading,
    isFilterApplied,
    sfState,
    showFilter,
  } = failureReasonContext;

  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [failureReason, setFailureReason] = useState("");
  const [view, setView] = useState("");
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };
  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleDeleteAssetCategory = async (failureReasonId) => {
    deleteFailureReason(failureReasonId)
      .then((res) => {
        successToast({ description: "Failure Reason is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Failure Reason" });
      });
  };
  const handleDeleteConfirmation = async (failureReasonId) => {
    confirmationSweetAlert({
      id: failureReasonId,
      handleConfirmation: handleDeleteAssetCategory,
    });
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const currentItems = failureReasonList?.items || [];

  const viewAssetCategory = (failureReasonId) => {
    setFailureReason(failureReasonId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editFailureReason = (failureReasonId) => {
    setFailureReason(failureReasonId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createAssetCategory = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };

  const onSuccessfulModal = () => {
    toggleModal();
    loadFailureReasonList();
  };
  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        <FailureReasonViewContainer
          failureReasonId={failureReason}
          setIsOpen={setIsOpen}
          editFailureReason={editFailureReason}
        />
      );
    } else if (view === VIEW.CREATE) {
      return <FailureReasonFormContainer onSuccessfulModal={onSuccessfulModal} />;
    } else if (view === VIEW.EDIT) {
      return <FailureReasonFormContainer failureReasonId={failureReason} onSuccessfulModal={onSuccessfulModal} />;
    }
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };

  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  return (
    <React.Fragment>
      {
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Failure Reason Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have a total of {failureReasonList?.pagination?.totalCount || 0} Failure Reason.</p>
              </BlockDes>
              <div className="d-flex flex-wrap">
                <FilteredByList
                  data={filterParams.selectedFailureReasons}
                  title={"Categories"}
                  isNotMap={true}
                  isStr={true}
                />
                <FilteredByList data={filterParams.createdAt} title={"Created At"} isMap={false} isStr={true} />
                <FilteredByList data={filterParams.updatedAt} title={"Updated At "} isMap={false} isStr={true} />
                <FilteredByList data={filterParams.searchText} title={"Search By"} isNotMap={true} isStr={true} />
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  id="menu-faliure-btn"
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <Check permission={["getEntity", "manageEntity"]}>
                      <li>
                        <Button
                          disabled={failureReasonList?.pagination?.totalCount === 0}
                          id="export-btn"
                          className={`btn btn-white btn-outline-light`}
                          type="export"
                          onClick={createExport}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                    </Check>
                    <Check permission={["manageEntity"]}>
                      <li className="nk-block-tools-opt">
                        <Button id="add-faliure" color="primary" className="btn-icon" onClick={createAssetCategory}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </Check>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      }
      <Block>
        <DataTable className="card-stretch">
          {
            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        id="search-faliure"
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <div id="asset-filter-btn" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      id="cancel-search"
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      id="search-input"
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by category"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                      }}
                    />
                    <Button id="search-faliure-btn" className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          {failureReasonLoading ? <></> : <DataTableBody compact>
            <DataTableHead>
              <DataTableRow size="md">
                <span className="sub-text">ID</span>
              </DataTableRow>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                        ? handleNameSort("DESC", "name")
                        : handleNameSort("ASC", "name");
                    }}
                  >
                    Category
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "name");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "name");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "name"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "created_at"
                        ? handleNameSort("DESC", "created_at")
                        : handleNameSort("ASC", "created_at");
                    }}
                  >
                    CreatedAt
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "created_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "created_at"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "created_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "created_at"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "updated_at"
                        ? handleNameSort("DESC", "updated_at")
                        : handleNameSort("ASC", "updated_at");
                    }}
                  >
                    UpdatedAt
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "updated_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "updated_at"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "updated_at");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "updated_at"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <Check permission={["manageEntity"]}>
                <DataTableRow></DataTableRow>
              </Check>
            </DataTableHead>
            <Sidebar toggleState={showFilter}>
              <FailureReasonListFilter />
            </Sidebar>
            {/*Head*/}
            {currentItems.length > 0 ? (
              currentItems.map((item, key) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow size="md" className="d-flex justify-content-center">
                      <span>{item.id}</span>
                    </DataTableRow>
                    <DataTableRow>
                      <div
                        id="faliure-view-btn"
                        className="user-card dropdown-toggle"
                        onClick={() => {
                          viewAssetCategory(item.id);
                        }}
                      >
                        <div className="user-name">
                          <span
                            data-toggle="tooltip"
                            title={item.name}
                            style={{
                              width: item.name?.length > 50 ? "350px" : "",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            className="tb-lead"
                          >
                            {item.name}
                          </span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">{format(new Date(item?.created_at || null), "dd/MM/yyyy HH:mm")}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="noWrap">{format(new Date(item?.updated_at || null), "dd/MM/yyyy HH:mm")}</span>
                    </DataTableRow>
                    <Check permission={["manageEntity"]}>
                      <DataTableRow className="d-flex justify-content-center">
                        <Button
                          id="edit-faliure"
                          onClick={() => {
                            editFailureReason(item.id);
                          }}
                        >
                          {" "}
                          <Icon name="edit"></Icon>
                          <span>Edit</span>
                        </Button>
                        <Button id="delete-faliure" onClick={() => handleDeleteConfirmation(item.id)}>
                          {" "}
                          <Icon name="trash"></Icon>
                          <span>Delete </span>
                        </Button>
                      </DataTableRow>
                    </Check>
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>}
          <div className="card-inner">
            {failureReasonList.pagination && failureReasonList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={failureReasonList.pagination.totalCount}
                paginate={failureReasonContext.updateShowListPage}
                currentPage={failureReasonList.pagination.currentPage}
                paginatePage={failureReasonContext.updateShowListSize}
              />
            ) : (
              !failureReasonLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {failureReasonLoading && <Skeleton count={10} className="w-100" />}
      </Block>
      <ModalViewer
        title="Failure Reason"
        isOpen={isOpen}
        size="sm"
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="Failure Reason - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default FailureReasonListCompact;
